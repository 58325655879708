import React from 'react';

const MoviesPage = () => {
	return (
		<div>
			<h1>В процесі розробки</h1>
		</div>
	);
};

export default MoviesPage;
